////////////////////////////////////////////////
/// DO NOT CHANGE THIS FILE.      //////////////
////////////////////////////////////////////////
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./auth.guard";
import { LoginComponent } from "./components/login/login.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";

/// New routes should be added in the guarded-routing.module.
const routes: Routes = [

  { path: "login", component: LoginComponent },
  {
    path: "guard",
    loadChildren: () => import("./guarded/guarded.module").then(m => m.GuardedModule),
    canLoad: [AuthGuard],
  },
  { path: "", component: LoginComponent },
  { path: "404", component: NotFoundComponent },
  { path: "**", redirectTo: "/404" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
