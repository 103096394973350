////////////////////////////////////////////////
/// DO NOT CHANGE THIS FILE.      //////////////
////////////////////////////////////////////////

import {Component} from "@angular/core";

@Component({ templateUrl: "not-found.template.html" })

export class NotFoundComponent {
}
