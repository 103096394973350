export class Tenant {
  name: string;
  address?: string;
  id: string;
  defaultEnv: string;
  stv4Affix: string;
  class: string;
  description: string;
}
export const TENANTS: Tenant[] = [
  {
    name: "Studio Dev (IntOne/E2E)",
    address: "HaloStudioDev.onmicrosoft.com",
    id: "27d5a614-5e32-4ce9-9207-b3a63945b5bb",
    defaultEnv: "IntOne",
    stv4Affix: "-intone.test",
    class: "is-success",
    description: "Recommended option if you are not sure. This is where a majority of the studio will configure and develop the game.",
  },
  {
    name: "Halo Production",
    id: "2094aafd-3045-4703-a6f1-ad112ff1babe",
    defaultEnv: "Prod",
    stv4Affix: "",
    class: "is-warning",
    description: "For production and RETAIL actions that interact with real players.",
  },
  {
    name: "Studio Stable (Shooter/CertOne)",
    address: "HaloStudioStable.onmicrosoft.com",
    id: "3693e644-c336-488b-bad9-6bd0f69007f0",
    defaultEnv: "certone",
    stv4Affix: "-certone.test",
    class: "is-primary",
    description: "For pre-release, pre-production testing and configuration.",
  },
];
