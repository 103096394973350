/// /////////////////////////////////////////////
/// DO NOT CHANGE THIS FILE.      //////////////
/// /////////////////////////////////////////////
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "../../services/auth.service";
import { TENANTS } from "../../guarded/elements/common/tenant-selection/tenants.constants";

@Component({
  selector: "login",
  templateUrl: "./login.template.html",
})
export class LoginComponent implements OnInit {
  isLoggedIn: boolean;
  showTenants: boolean = false;
  user: any;
  spartanToken: string;
  canRetry: boolean = false;

  loginError: any = null;
  state: any = {

    signInError: false,
  };

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
    console.debug("login: on init");
    this.auth.handleReturn(); // handles return if any

    console.debug("login: handle return complete");
    this.isLoggedIn = this.auth.check();

    console.debug(`login: is logged in? ${this.isLoggedIn}`);

    if (this.isLoggedIn) {
      this.user = this.auth.getUser();
      console.debug("login: fetching ST");
      let environment = localStorage.getItem("lastEnv");
      const lastTenantId = localStorage.getItem("tenant-id");
      const currentTenant = TENANTS.filter(t => t.id === this.auth.aadTenantId)[0];
      console.debug(`Last t(${lastTenantId}), current t(${currentTenant.id})`);

      if (!lastTenantId || lastTenantId !== currentTenant.id || !environment) {
        environment = currentTenant.defaultEnv;
      }

      console.debug(`ENV AT LOGIN: ${environment} under t(${this.auth.aadTenantId})`);
      const stv4Host = `https://settings${currentTenant.stv4Affix}.svc.halowaypoint.com`;
      const stv4Aud = environment.toLocaleLowerCase() === "prod" ? "https://settings.svc.halowaypoint.com/spartan-token" : "https://settings.test.svc.halowaypoint.com/spartan-token";

      this.auth.getSpartanToken(stv4Aud, stv4Host).then(token => {
        this.spartanToken = token.SpartanToken;
        let title = localStorage.getItem("lastTitle");
        title = title || "oban";
        this.router.navigate([`/guard/${environment}/${title}`]);
      }).catch(e => {
        console.error(e);
        this.state.signInError = true;
        this.loginError = e;
      });
    } else {
      const requestedTenant = localStorage.getItem("nextTenant");
      if (requestedTenant) {
        localStorage.removeItem("nextTenant");
        this.auth.login().then();
      } else {
        this.showTenants = true;
      }
      this.showTenants = !localStorage.getItem("tenant-id");
    }

    if (!this.showTenants) {
      setTimeout(() => {
        this.canRetry = true;
      }, 15000);
    }
  }
  loginStart(): void {
    this.auth.login().then();
  }

  resetLogin(): void {
    localStorage.clear();
    window.location.replace(`${window.location.protocol}//${window.location.host}`);
  }
}
