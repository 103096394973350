////////////////////////////////////////////////
/// DO NOT CHANGE THIS FILE.      //////////////
////////////////////////////////////////////////
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./services/auth.service";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate, CanLoad {

    private stValidationUrl = "https://hacs-intone.test.svc.halowaypoint.com/xboxusers/fry?auth=ST";

    constructor(private auth: AuthService, private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | Promise<boolean> {

        return this.handle().then(pass => {
            return pass;
        });
    }

    canLoad(): boolean | Promise<boolean> {
        return this.handle().then(pass => {
            return pass;
        });
    }

    async handle(): Promise<boolean> {
        console.debug("auth guard hit");
        let isLoggedIn = this.auth.check();

        //try check params
        if (!isLoggedIn) {
            this.auth.handleReturn();
        }

        //check again
        isLoggedIn = this.auth.check();

        if (!isLoggedIn) {
            localStorage.setItem('post-login-path', window.location.pathname);
            this.router.navigate(["/login"]);
            return false;
        }

        return true;
    }
}
