////////////////////////////////////////////////
/// ATTENTION:                               ///
/// Add your files to guarded.module.ts      ///
/// This file should only contain the base   ///
///    site that will be delivered without   ///
///    user authentication.                  ///
////////////////////////////////////////////////
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { ApplicationInsightsService } from "./services/applicationInsights.service";
import { AuthService } from "./services/auth.service";
import { LoginTenantSelectionComponent } from "./components/login/login-tenant-selection.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    LoginTenantSelectionComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "loop" }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ToastrModule.forRoot(),
  ],
  providers: [AuthService, ApplicationInsightsService],
  bootstrap: [AppComponent],
})
export class AppModule { }
