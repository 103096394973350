/// ////////////////////////////////////////////////////////////////////////////////////////////
/// DO NOT CHANGE THIS FILE                                             ///////////////////////
/// ////////////////////////////////////////////////////////////////////////////////////////////
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as adalLib from "adal-angular";
import * as AuthenticationContext from "adal-angular/dist/adal.min";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";

// tslint:disable-next-line:no-duplicate-imports
import { Options } from "adal-angular";
import { SpartanToken } from "../models/spartan-token";
import { Tenant, TENANTS } from "../guarded/elements/common/tenant-selection/tenants.constants";
// import { ApplicationInsightsService } from "./applicationInsights.service";

@Injectable()
export class AuthService {
  static count = 1;

  static msftTenantId = "a9063893-a7c6-46ba-9e91-82f09ea18d17";

  aadTenantId = "a9063893-a7c6-46ba-9e91-82f09ea18d17";
  aadInstance = "https://login.microsoftonline.com/";
  clientId = "abb09453-882a-46b8-b0b1-e9bac4fe58c0";
  myCount = AuthService.count++; // eslint-disable-line
  timeCount = 0;

  private tokenCurrentAudience = "";
  private spartanTokenHost = "";
  private authContext: AuthenticationContext;
  private authConfig: Options;
  private oauthData = {
    isAuthenticated: false,
    userName: "",
    loginError: "",
    profile: null,
  };
  protected spartanToken: SpartanToken;

  constructor(
    private http: HttpClient,
    public notifier: ToastrService,
    // private appInsights: ApplicationInsightsService,
  ) {
    this.aadTenantId = localStorage.getItem("tenant-id") || "a9063893-a7c6-46ba-9e91-82f09ea18d17";
    this.initAuthContext();
  }

  get Alias(): string {
    const user = this.authContext.getCachedUser();
    const name: string = user.userName;
    return name.substr(0, name.indexOf("@"));
  }

  get Id(): string {
    const user = this.authContext.getCachedUser();
    const oid = `oid(${user.profile.oid})`;
    return oid;
  }

  get Auid(): string {
    return `auid(${this.getUser().profile.oid})`;
  }

  /**
   * Updates the stored Tenant ID
   * @param newTenentId - New Tenant ID
   */
  changeTenantId(newTenentId: string, newAadInstance?: string): void {
    this.aadTenantId = newTenentId;
    this.aadInstance = newAadInstance || this.aadInstance;
    localStorage.setItem("tenant-id", this.aadTenantId);
    localStorage.setItem("nextTenant", this.aadTenantId);
    this.initAuthContext();
  }

  /**
   * Sets up the authentication context, to be called after updating the member fields corresponding to the authConfig field
   */
  initAuthContext(): void {
    this.authConfig = {
      instance: this.aadInstance,
      tenant: this.aadTenantId,
      clientId: this.clientId,
      postLogoutRedirectUri: window ? window.location.origin : null,
      cacheLocation: "localStorage",
      popUp: false,
      expireOffsetSeconds: 120,
    };
    this.authContext = adalLib.inject(this.authConfig);
    const user = this.authContext.getCachedUser();
    // ADAL auth fix from https://github.com/AzureAD/azure-activedirectory-library-for-js/issues/580
    if (user) {
      this.authContext.config.extraQueryParameter = `login_hint=${user.userName}`;
    }
    this.handleReturn();
  }

  /** Fetches the spartan token. */
  async getSpartanToken(
    tokenAudience: string = "https://settings.test.svc.halowaypoint.com/spartan-token",
    spartanTokenHost: string = "https://settings-IntOne.test.svc.halowaypoint.com",
  ): Promise<SpartanToken> {
    if (!this.spartanToken
      || moment().isSameOrAfter(this.spartanToken.ExpiresUtc.ISO8601Date)
      || this.tokenCurrentAudience !== tokenAudience
      || this.spartanTokenHost !== spartanTokenHost) {
      this.tokenCurrentAudience = tokenAudience;
      this.spartanTokenHost = spartanTokenHost;
      console.debug("AUTH: ST null or expired");

      try {
        const token = await this.acquireToken(tokenAudience);
        const reqData = {
          Audience: "urn:343:s3:services",
          MinVersion: 4,
          Proof: [{
            TokenType: "AAD_v1Token",
            Token: token,
          }],
        };

        console.debug("AUTH: ST RC FETCH");

        // this.appInsights.setUserId(this.Id);

        const response = await this.http.post(`${spartanTokenHost}/spartan-token`, reqData).toPromise();
        this.spartanToken = response as SpartanToken;
      } catch (e) {
        this.renewToken(tokenAudience);
        console.error(e);
        throw (e);
      }
    }

    const date = new Date();
    date.setTime(date.getTime() + (10 * 60 * 1000)); // 10m cookie
    document.cookie = `x-343-st=${this.spartanToken.SpartanToken};expires=${date.toUTCString()};path=/`;
    return this.spartanToken;
  }

  /** Executes the login flow. */
  async login() {
    this.authContext.login();
  }

  /** Executes the logout flow. */
  async logOut() {
    // this.appInsights.clearUserId();
    localStorage.clear();
    this.authContext.logOut();
  }

  /** Checks if the user is logged in. Returns boolean. */
  check(): boolean {
    const user = this.authContext.getCachedUser();
    return user !== null;
  }

  /** Fetches the currently logged in user. */
  getUser(): any {
    return this.authContext.getCachedUser();
  }

  getCurrentTenant(): Tenant {
    return TENANTS.filter(t => t.id === this.aadTenantId)[0];
  }

  /** Acquires an AAD token. */
  async acquireToken(audience: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.authContext.acquireToken(audience, (error: string, tokenOut: string) => {
        if (error) {
          this.authContext.acquireTokenRedirect(audience);
          reject(error);
        } else {
          resolve(tokenOut);
        }
      });
    });
  }

  /** Handles the AAD return. */
  handleReturn() {
    this.authContext.handleWindowCallback();
  }

  private renewToken(url: string) {
    this.notifier.warning("Auto renewing aad token", "renewToken");
    this.acquireToken(url).then(resource => this.authContext.clearCacheForResource(resource));
  }

  private updateDataFromCache(resource: string): void {
    const token = this.authContext.getCachedToken(resource);
    this.oauthData.isAuthenticated = token !== null && token.length > 0;
    const user = this.authContext.getCachedUser() || { userName: "", profile: null };
    this.oauthData.userName = user.userName;
    this.oauthData.profile = user.profile;
    this.oauthData.loginError = this.authContext.getLoginError();
  }
}
